<template>
    <td class="align-middle">
        <slot></slot>
    </td>
</template>

<script>
export default {
name: "GridCell"
}
</script>

<style scoped>

</style>
