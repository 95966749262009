<template>
    <th class="text-center" scope="col"><slot></slot></th>
</template>

<script>
export default {
    name: "GridCellHeader"
}
</script>

<style scoped>

</style>
