<template>
    <tr class="border-bottom"><slot></slot></tr>
</template>

<script>
export default {
name: "GridRow"
}
</script>

<style scoped>

</style>
